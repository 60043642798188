define([
  'modules/admin/controllers/default',
  'modules/admin/views/tasks/progress',
  'upx.modules/ManagementModule/models/Task',
],
(DefaultController, ProgressView, TaskModel) => DefaultController.extend({
  progress(id) {
    const self = this;
    const task = new TaskModel({
      id,
    });

    $.when(task.fetch()).then(
      () => {
        self.getRegionByPath('main.page.content').show(
          new ProgressView({
            model: task,
          }),
        );
      },
    );
  },
}));
