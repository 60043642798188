define([
  'backbone',
  'communicator',
],
(Backbone, Communicator) => Backbone.Marionette.Controller.extend({
  /**
             * Initializes the component
             * @param options
             */
  initialize() {
    Communicator.mediator.bind('admin/component/sidebar/handle', this.handle, this);
  },

  /**
             * Handles sidebar manupulation
             */
  handle(mode, extra) {
    const self = this;
    const page = $('#page-container');
    const header = $('header');
    const sidebar = $('#sidebar');
    const sScroll = $('.sidebar-scroll');

    if (mode === 'init') {
      // Init sidebars scrolling (if we have a fixed header)
      if (header.hasClass('navbar-fixed-top') || header.hasClass('navbar-fixed-bottom')) {
        self.handle('sidebar-scroll');
      }

      // Close the other sidebar if we hover over a partial one
      // In smaller screens (the same applies to resized browsers) two visible sidebars
      // could mess up our main content (not enough space), so we hide the other one :-)
      $('.sidebar-partial #sidebar')
        .mouseenter(() => { self.handle('close-sidebar-alt'); });
      $('.sidebar-alt-partial #sidebar-alt')
        .mouseenter(() => { self.handle('close-sidebar'); });
    } else {
      const windowW = window.innerWidth
                        || document.documentElement.clientWidth
                        || document.body.clientWidth;

      if (mode === 'toggle-sidebar') {
        if (windowW > 991) { // Toggle main sidebar in large screens (> 991px)
          page.toggleClass('sidebar-visible-lg');

          if (page.hasClass('sidebar-visible-lg')) {
            self.handle('close-sidebar-alt');
          }

          // If 'toggle-other' is set, open the alternative sidebar when we close this one
          if (extra === 'toggle-other') {
            if (!page.hasClass('sidebar-visible-lg')) {
              self.handle('open-sidebar-alt');
            }
          }
        } else { // Toggle main sidebar in small screens (< 992px)
          page.toggleClass('sidebar-visible-xs');

          if (page.hasClass('sidebar-visible-xs')) {
            self.handle('close-sidebar-alt');
          }
        }
      } else if (mode === 'toggle-sidebar-alt') {
        if (windowW > 991) { // Toggle alternative sidebar in large screens (> 991px)
          page.toggleClass('sidebar-alt-visible-lg');

          if (page.hasClass('sidebar-alt-visible-lg')) {
            self.handle('close-sidebar');
          }

          // If 'toggle-other' is set open the main sidebar when we close the alternative
          if (extra === 'toggle-other') {
            if (!page.hasClass('sidebar-alt-visible-lg')) {
              self.handle('open-sidebar');
            }
          }
        } else { // Toggle alternative sidebar in small screens (< 992px)
          page.toggleClass('sidebar-alt-visible-xs');

          if (page.hasClass('sidebar-alt-visible-xs')) {
            self.handle('close-sidebar');
          }
        }
      } else if (mode === 'open-sidebar') {
        if (windowW > 991) { // Open main sidebar in large screens (> 991px)
          page.addClass('sidebar-visible-lg');
        } else { // Open main sidebar in small screens (< 992px)
          page.addClass('sidebar-visible-xs');
        }

        // Close the other sidebar
        self.handle('close-sidebar-alt');
      } else if (mode === 'open-sidebar-alt') {
        if (windowW > 991) { // Open alternative sidebar in large screens (> 991px)
          page.addClass('sidebar-alt-visible-lg');
        } else { // Open alternative sidebar in small screens (< 992px)
          page.addClass('sidebar-alt-visible-xs');
        }

        // Close the other sidebar
        self.handle('close-sidebar');
      } else if (mode === 'close-sidebar') {
        if (windowW > 991) { // Close main sidebar in large screens (> 991px)
          page.removeClass('sidebar-visible-lg');
        } else { // Close main sidebar in small screens (< 992px)
          page.removeClass('sidebar-visible-xs');
        }
      } else if (mode === 'close-sidebar-alt') {
        if (windowW > 991) { // Close alternative sidebar in large screens (> 991px)
          page.removeClass('sidebar-alt-visible-lg');
        } else { // Close alternative sidebar in small screens (< 992px)
          page.removeClass('sidebar-alt-visible-xs');
        }
      } else if (mode == 'sidebar-scroll') { // Init sidebars scrolling
        if (sScroll.length && (!sScroll.parent('.slimScrollDiv').length)) {
          // Initialize Slimscroll plugin on both sidebars
          // sScroll.slimScroll({ height: $(window).height(), color: '#fff', size: '3px', touchScrollStep: 100 });

          // Resize sidebars scrolling height on window resize or orientation change
          // $(window).resize(sidebarScrollResize);
          // $(window).bind('orientationchange', sidebarScrollResizeOrient);
        }
      }
    }

    return false;
  },
}));
