var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"breadcrumbsBackButton") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"breadcrumbsForwardButton") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":14,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                <a href=\"javascript: history.back();\">\n                    <i class=\"fa fa-arrow-left\"></i> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"go_back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":53},"end":{"line":7,"column":83}}}))
    + "\n                </a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                <a href=\"javascript: history.forward();\" class=\"pull-right\">\n                    "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"go_forward",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":53}}}))
    + " <i class=\"fa fa-arrow-right\"></i>\n                </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"page-header\"></div>\n<div id=\"breadcrumbs\">\n"
    + ((stack1 = require("/home/luki/workspace/projects/storekeeper/app-backoffice/app/scripts/modules/common/templates/helpers/compare.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"breadcrumbsBackButton") : depth0),"||",(depth0 != null ? lookupProperty(depth0,"breadcrumbsForwardButton") : depth0),{"name":"common$compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":16,"column":23}}})) != null ? stack1 : "")
    + "</div>\n<div id=\"page\"></div>";
},"useData":true});