define([
  'application',
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/layout',
  'modules/common/components/managers/menu',
  'modules/admin/templates/admin/sidebar',
  'communicator',
  'modules/admin/views/admin/menu/menu',
  'modules/upx/collections/users',
  'modules/profile/models/profile',
  'modules/shop/models/upx/DefaultShopAddress',
  'modules/common/components/historyBreadcrumb',
  'modules/common/components/layout/options',
],
(App, $, _, Backbone, Layout, MenuManager, Template, Communicator, Menu, Users, ProfileModel, DefaultShopAddressModel, HistoryBreadcrumb, LayoutOptions) => Layout.extend({
  template: Template,

  events: {
    'click a[data-action="signout"]': function (e) {
      e.preventDefault();
      const user = Users.findWhere({ active: true });
      $.when(user.logout()).then(() => {
        Backbone.history.navigate('/', { trigger: true });
      });
    },
    'click a[data-action="goToHome"]': 'goToHome',

  },

  regions: {
    menu: {
      selector: '#menu',
      viewClass: Menu,
      viewOptions() {
        return {
          collection: App.com(MenuManager).get('main'),
        };
      },
    },
    'powered-by': {
      selector: '#sidebar-powered-by',
    },
  },

  initialize(options) {
    options = options || {};
    this.options = options = $.extend(true, {},
      this.defaults,
      LayoutOptions.getData(this.getName()),
      options);
    const self = this;

    ProfileModel.on('change', () => {
      self.$el.find('.sidebar-user-name').html(ProfileModel.getFullProfileName());
      self.$el.find('[alt="avatar"]').attr('src',
        ProfileModel.getAvatarUrl(false, false, self.getDefaultAvatarFromConfig()));
    });
  },

  goToHome() {
    Backbone.history.navigate(HistoryBreadcrumb.getIndexFragment(), { trigger: true });
  },

  /**
             *
             * @returns {boolean|string}
             */
  getDefaultAvatarFromConfig() {
    let avatarUrl = false;
    if (
      App.settings.admin !== undefined
                    && App.settings.admin.sidebar !== undefined
                    && App.settings.admin.sidebar.default_avatar !== undefined
    ) {
      avatarUrl = App.settings.admin.sidebar.default_avatar;
    }
    return avatarUrl;
  },

  serializeData() {
    const data = {
      name: ProfileModel.getFullProfileName(),
      companyName: DefaultShopAddressModel.getCompanyName(),
      avatarUrl: ProfileModel.getAvatarUrl(false, false, this.getDefaultAvatarFromConfig()),
      sidebar: {
        title: 'StoreKeeper',
        description: '',
        icon: 'hi hi-stop',
      },
    };

    if (App.settings.admin !== undefined && App.settings.admin.sidebar !== undefined) {
      const sideBarSettings = App.settings.admin.sidebar;

      data.sidebar.title = sideBarSettings.title || data.sidebar.title;
      data.sidebar.description = sideBarSettings.description || data.sidebar.description;
      data.sidebar.icon = sideBarSettings.icon || data.sidebar.icon;
    }

    return data;
  },
}));
