define([
  'application',
  'backbone',
  'modules/admin/views/admin/menu/menuItem',
],
(App, Backbone, MenuItem) => Backbone.Marionette.CollectionView.extend({
  tagName: 'ul',

  className: 'sidebar-nav',

  childView: MenuItem,

  events: {
    'click .sidebar-nav-menu': 'clickedMenuLink',
  },

  /**
             * Set active menu item on adding child
             */
  onAddChild() {
    this.setActive();
  },

  /**
             * Set active menu item
             */
  setActive() {
    const links = this.$el.find('a');
    let maxLen = 0;
    let maxMatch = '';

    $.each(links, (key, element) => {
      const route = $(element).attr('href').replace('#', '');
      const { fragment } = Backbone.history;

      if (fragment.indexOf(route) > -1 && route.length > maxLen) {
        maxLen = route.length;
        maxMatch = route;
      }
    });

    if (maxLen > 0) {
      this.$el.find('a').removeClass('active');
      this.$el.find(`a[href="#${maxMatch}"]`).addClass('active');

      // check if a nested child is active
      // when it's active open the menu to show the item
      const sidebarNavMenu = this.$el.find(`a[href="#${maxMatch}"]`).parent().parent().prev();
      if (sidebarNavMenu.length > 0 && sidebarNavMenu.hasClass('sidebar-nav-menu')) {
        sidebarNavMenu.addClass('open').next().slideDown(0);
      }
    }
  },

  clickedMenuLink(e) {
    const link = $(e.currentTarget);
    const target = $(e.target);

    if (target.hasClass('sidebar-nav-indicator') || target.hasClass('no-route')) {
      if (link.parent().hasClass('active') !== true) {
        if (link.hasClass('open')) {
          link.removeClass('open').next().slideUp(250);
        } else {
          $('.sidebar-nav-menu.open').removeClass('open').next().slideUp(250);
          link.addClass('open').next().slideDown(250);
        }
      }
      return false;
    }
  },

}));
