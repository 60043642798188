define([
  'jquery',
  'upx.modules/BlogModule/models/Configuration',
  'modules/upx/components/upx',
  'modules/common/collections/languages',
], (
  $, BlogModule, Upx, LanguageCollection,
) => {
  const model = BlogModule.extend({
    offline: true,

    loaded: false,

    load(force) {
      const def = $.Deferred();
      if (!this.loaded || force) {
        const self = this;
        $.when(Upx.call('BlogModule', 'listConfigurations',
          {
            start: 0,
            limit: 1,
            filters: [{
              name: 'is_default__=',
              val: 'true',
            }],
          })).then((response) => {
          self.set(response.data[0]);
          self.loaded = true;
          def.resolve();
        }, () => {
          def.reject('Failed to load model!');
        });
      } else {
        def.resolve();
      }
      return def.promise();
    },

    /**
         * Returns the main lang
         * @returns {*}
         */
    getMainLang() {
      return this.get('lang');
    },

    /**
         * List all available langs including the main lang
         * @returns {Array}
         */
    listAvailableLangs(config) {
      config = config || {};

      const langs = [];

      if (this.has('final_langs')) {
        $.each(this.get('final_langs'), (key, lang) => {
          langs.push(lang);
        });
      }

      if (config.excludeMainLanguage === undefined || !config.excludeMainLanguage) {
        langs.push(this.get('lang'));
      }

      return langs;
    },

    getTranslatedLanguageCollection(listAvailableLangsConfig) {
      const availableLanguages = this.listAvailableLangs(listAvailableLangsConfig);
      return LanguageCollection.getCollectionFromArray(availableLanguages);
    },

    unload() {
      this.clear();
      this.getCacheStorage().cleanup();
    },
  });

  return new model();
});
