define([
  '@storekeeper/sentry',
  'application',
], (SKSentry, Application) => {
  const sentryConfig = {
    dsn: 'https://30d8f49ae03a46b8ad81c03d66dc336e@sentry.io/1819929',
  };
  if (!!window.storekeeper && 'version' in window.storekeeper) {
    sentryConfig.release = `${window.storekeeper.version}-backoffice`;
  }

  SKSentry.enableSentry(sentryConfig);

  // Make a public reference
  window.App = Application;

  // Start the application
  Application.start();
});
