var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-md-12\">\n        <div class=\"block full\">\n            <div class=\"block-title\">\n                <h2><strong>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"task_progress",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":64}}}))
    + "</strong></h2>\n            </div>\n            <div class=\"text-center\">\n                <h4>"
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":28}}}) : helper)))
    + "</h4>\n                <br>\n                <div class=\"progress progress-striped active\">\n                    <div class=\"progress-bar progress-bar-info\" role=\"progressbar\" style=\"width: 100%\"></div>\n                </div>\n                <h3>\n                    <span id=\"percentage\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"task_execution_last") : depth0)) != null ? lookupProperty(stack1,"perdone") : stack1), depth0))
    + " %</span>\n                </h3>\n                <small id=\"message\"></small>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});