define([
  'modules/common/routers/aclRouter',
  'modules/admin/controllers/tasks',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, Controller, LoggedIn, CommerceAuthAcl, NotAcl) => AclRouter.extend({

  appRoutes: {
    'tasks/progress/:id': 'progress',
  },

  acls: [
    new LoggedIn(),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
  ],

  initialize(options) {
    this.controller = new Controller(options);
  },
}));
