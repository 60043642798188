define([
  'jquery',
  'backbone',
],
($, Backbone) => {
  const comp = Backbone.Marionette.Controller.extend({
    initialize() {
      String.prototype.replaceAll = function (target, replacement) {
        return this.split(target).join(replacement);
      };
      String.prototype.startsWith = function (str) {
        return this.slice(0, str.length) == str;
      };
      String.prototype.endsWith = function (str) {
        return this.slice(-str.length) == str;
      };
    },
    /**
             *
             * @param str
             * @param num
             * @param indentString
             * @returns {*}
             */
    indent(str, num, indentString) {
      indentString = indentString || '  ';
      if (num <= 0) {
        return str;
      }
      num = num || 1; // set to 1 if unknown
      if (str == '' || str == undefined) {
        // empty string just return indent
        return this.repeatString(indentString, num);
      }
      str = str.replace(
        /^(?=.)/gm,
        this.repeatString(indentString, num),
      );
      return str;
    },
    /**
             *
             * @param text
             * @param num
             * @returns {string}
             */
    repeatString(text, num) {
      return new Array(num + 1).join(text);
    },

    /**
             * Count the occurrences in a string
             * @param needle
             * @param text
             * @returns {number}
             */
    countOcccurrences(needle, text) {
      let count = 0;
      let pos = text.indexOf(needle);

      while (pos != -1) {
        count++;
        pos = text.indexOf(needle, pos + 1);
      }

      return count;
    },

    toSlug(text) {
      text = text.toLowerCase();
      text = text.replace(/\s+/g, '-');
      text = text.replace(/[^a-z0-9-]/g, '');
      text = text.trim();
      return text;
    },
    /**
             *
             * @param val
             * @param precision
             * @param forceFixed
             * @returns {*}
             */
    roundNumber(val, precision, forceFixed) {
      if (typeof (precision) !== 'number') {
        precision = 2;
      }

      if (val.length > 0 || forceFixed) {
        return parseFloat(val).toFixed(precision);
      }
      return val;
    },
    /**
             * used in ModelBinder ModelToView
             * @param value
             * @param precision
             * @returns {*}
             */
    toCurrency(value, precision) {
      if (value != undefined) {
        value = this.roundNumber(value, precision, true);
        value = value
          .toString() // to string
          .replaceAll('.', ',') // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // puts dot every 3 numbers
        return value;
      }
      return '';
    },
    /**
             * used in ModelBinder ViewToModel
             * @param value
             * @returns {*}
             */
    toFloat(value) {
      value = value.replaceAll('.', '');
      value = value.replaceAll(',', '.');

      if (value.length > 0) {
        return parseFloat(value);
      }
      return value;
    },

    nl2br(str, is_xhtml) {
      const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
      return (`${str}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
    },

    /**
             * https://stackoverflow.com/a/18650828
             * @param bytes
             * @return {string}
             */
    getSize(bytes) {
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 B';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
    },

    convertDatabaseNameToNormalName(value) {
      return value.replaceAll('_', ' ');
    },

  });
  return new comp();
});
