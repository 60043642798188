define([
  'backbone',
  './../components/component',
  'jquery',
],
(Backbone, Component, $) => {
  const comp = Component.extend({
    /**
             *
             */
    initialize() {
      this.isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
      // Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
      this.isFirefox = typeof InstallTrigger !== 'undefined'; // Firefox 1.0+
      this.isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
      // At least Safari 3+: "[object HTMLElementConstructor]"
      this.isChrome = !!window.chrome && !this.isOpera; // Chrome 1+
      this.isIE = /* @cc_on!@ */false || !!document.documentMode; // At least IE6
    },

  });
  return new comp();
});
